import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { StepConnector, Button, StepButton, Stepper, Step } from "@material-ui/core";
import queryString from "query-string";
import Axios from "axios";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { setLoading } from "../../ducks/loading";
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs";
import { DATASETS, GET_INDUSTRIES_LIST, GET_OBJECT_ELEMENTS, GET_OBJECTS, SELECTED_DATASET, SELECTED_DATASET_DISPLAY_CONFIG, SELECTED_DATASET_TABLE_CONFIG } from "../../config";
import TableInfo from "./TableInfo";
import TableElementConfig from "./TableElementConfig";
import TableSettings from "./TableSettings";
import { regularExpRemoveChar, rulesRegularExpression } from "../../helpers";
import ExpressionDialog from "./ExpressionDialog";
import CustomDialog from "../../components/CustomDialog";


const QontoConnector = withStyles({
    active: {
        "& $line": {
            // borderColor: "yellow"
        }
    },
    completed: {
        "& $line": {
            // borderColor: "yellow"
        }
    },
    line: {
        borderColor: "rgba(0,0,0,0.3)"
    }
})(StepConnector);

const useStyles = makeStyles(theme => ({
    root: {
        // width: "95vw",
        // backgroundColor: "white",
        height: "auto",
        // paddingTop: 45,
        // paddingTop: "2rem",
        // marginLeft: 75
    },
    wrapper: {
        padding: '0 20px',
        marginTop: '1rem'
    },
    nextButton: {
        color: "white",
        textAlign: "center",
        fontSize: "14px",
        // width: "135px",
        marginRight: theme.spacing(1)
    },
    backButton: {
        color: "#58595B",
        textAlign: "center",
        fontSize: "14px",
        // width: "135px",
        marginRight: theme.spacing(1),
        border: "1px solid  #58595B",
        borderRadius: "4px",
        textTransform: 'capitalize',
    },
    breadcrumbsRoot: {
        marginTop: 2
    },
    breadcrumbsActive: {
        color: "#58595B",
        fontSize: 12,
        margin: "3px 0 0"
    },
    breadcrumbsLink: {
        color: theme.palette.primary.main,
        fontSize: 12,
        textDecoration: "none"
    },
    stepper: {
        // width: "70%",
        marginRight: "auto",
        marginLeft: "auto",
        // margin: '15px auto 10px',
        borderRadius: 8,
        paddingBottom: 10,
        backgroundColor: 'transparent'
    },
    stepperContentRoot: {
        marginTop: 20,
        minHeight: 300
    },
    stepperActions: {
        display: "flex",
        backgroundColor: "none",
        justifyContent: "flex-end",
        padding: '14px 24px',
        '& .MuiButton-root': {
            height: 35,
            padding: '0 10px',
            textTransform: 'capitalize'
        }
    }
}));

export const transactionSetupSteps = ["Table Info", "Define Table", "Table Configuration",];
export const componentsList = [
    { name: 'Transaction Table', id: 'TABLE' },
    // { name: 'Trend Line', id: 'TREND_LINE' },
    // { name: 'Charts', id: 'CHARTS' },
]
export const componentTableDuration = [
    { name: 'Last 10 Days', id: 10 },
    { name: 'Last 20 Days', id: 20 },
    { name: 'Last 30 Days', id: 30 },
]
export const accumulators = [
    // { name: 'First', id: 'FIRST' },
    { name: 'Last', id: 'LAST' },
    { name: 'Sum', id: 'SUM' },
    { name: 'Count', id: 'COUNT' },
    { name: 'Min', id: 'MIN' },
    { name: 'Max', id: 'MAX' }
]
export const orderbyElements = [
    { name: 'Ascending', id: 1, value: 'ASCENDING' },
    { name: 'Descending', id: -1, value: 'DESCENDING' }
]
export const noofTRans = [
    { name: '5', id: 5 },
    { name: '10', id: 10 },
    { name: '20', id: 20 },
    { name: '30', id: 30 },
    { name: '40', id: 40 },
    { name: '50', id: 50 },
    { name: '100', id: 100 },
]
export const tableRange = [
    { name: 'Greaterthan', id: 'GREATERTHAN' },
    { name: 'Greaterthan Or Equal', id: 'GREATERTHAN_OR_EQUAL' },
    { name: 'Lessthan', id: 'LESSTHAN' },
    { name: 'Lessthan Or Equal', id: 'LESSTHAN_OR_EQUAL' },
    { name: 'Equal To', id: 'EQUALTO' },
    // { name: 'Top', id: 'TOP' },
    // { name: 'Bottom', id: 'BOTTOM' },
];

export const expressionsList = [
    { id: 1, name: "+", value: "+", hoverText: 'Addition' },
    { id: 2, name: "-", value: "-", hoverText: 'Subtraction' },
    { id: 3, name: "*", value: "*", hoverText: 'Multiplication' },
    { id: 4, name: "/", value: "/", hoverText: 'Division' },
    // { id: 5, name: "||", value: "||" },
    // { id: 6, name: "&&", value: "&&" },
    // { id: 7, name: "!", value: "!" },
    { id: 5, name: "^", value: "^", hoverText: 'Power' },
    // { id: 9, name: "=", value: "=" },
    // { id: 10, name: ">", value: ">" },
    // { id: 11, name: "<", value: "<" },
    // { id: 12, name: ">=", value: ">=" },
    // { id: 13, name: "<=", value: "<=" },
    { id: 6, name: "(", value: "(", hoverText: 'Open Bracket' },
    { id: 7, name: ")", value: ")", hoverText: 'Close Bracket' },
]


const TransactionStepper = props => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [activeStep, setActiveStep] = useState(0);
    const [currentSeasonStatus, setSeasonCurrentStatus] = useState('')
    const [query, setQuery] = useState({});
    const [tableDetails, setTableDetails] = useState(null)
    const [details, setDetails] = useState({
        name: '',
        display_name: '',
        industry: null,
        component: componentsList[0],
        description: '',
        dataDuration: null,
        object: null,
        related_object: null,
        configElements: [],
        groupTransTable: null,//[]
        elementSortingOn: null,
        elementSortingBy: null,
        no_of_records: null,
    });
    const [industries, setIndustries] = useState([]);
    const [objects, setObjects] = useState([]);
    const [objectElementsList, setObjectElementsList] = useState([]);
    const [objElementsList, setObjElementsList] = useState([]);
    const [relObjectElementsList, setRelObjectElementsList] = useState([]);
    const [selectedTable, setSelectedTable] = useState();
    const [sortingElements, setSortingElements] = useState([]);
    const [editElement, setEditElement] = useState();
    const [displayValue, setDispalyValue] = useState();
    const [response_elements, setResponse_elements] = useState([]);
    const [tableRules, setTableRules] = useState({ rules: [] });
    const [expressionBuilderDialog, setExpressionBuilderDialog] = useState(false);
    const [expressiontags, setExpressionTags] = useState([]);
    const [expressionMessage, setExpressionMessage] = useState({
        body: '',
        expression: '',
        basedOn: null,
        search: '',
        rowOnChange: null
    });
    const [selectedTableDetails, setSelectedTableDetails] = useState({ settingsElements: null, configElements: null })
    const [industryConfirmDialog, setIndustryConfirmDialog] = useState(false);


    useEffect(() => {
        if (props.location.search) {
            let query = queryString.parse(props.location.search);
            setActiveStep(transactionSetupSteps.indexOf(query.step));
        }
    }, []);

    useEffect(() => {
        if (!props.match?.params?.tableId) return; // Early return if there's no tableId
        const fetchData = async () => {
            switch (activeStep) {
                case 0:
                    await getTableInfo();
                    break;
                case 1:
                    await getTableSettingsStep();
                    break;
                case 2:
                    await getTableConfigStep();
                    break;
                default:
                    await getTableInfo();
                    break;
            }
        };
        fetchData();
    }, [props.match?.params?.tableId, activeStep]);


    useEffect(() => { getIndustries() }, []);
    useEffect(() => { getObjects() }, [details.industry]);

    useEffect(() => {
        if (details.object) getObjectElements(details.object?._id);
    }, [details.object]);

    useEffect(() => {
        if (details.related_object) getRelatdObjectElements(details.related_object?._id);
    }, [details.related_object]);

    useEffect(() => {
        if (objElementsList || relObjectElementsList) {
            setObjectElementsList([...objElementsList, ...relObjectElementsList])
        }
    }, [objElementsList, relObjectElementsList])

    // -------------update sorting elements------
    useEffect(() => {
        if (objects.length && industries.length && tableDetails) {
            setDetails({
                ...details,
                name: tableDetails?.name ? tableDetails?.name : '',
                industry: tableDetails?.industry_id ? industries?.find((d) => d._id === tableDetails?.industry_id) : null,
                object: tableDetails?.object_id ? objects?.find((d) => d._id === tableDetails?.object_id) : null,
                related_object: tableDetails?.related_object_id ? objects?.find((d) => d._id === tableDetails?.related_object_id) : null,
            });
        }
    }, [objects.length, industries.length, tableDetails])

    useEffect(() => {
        if (objectElementsList.length && tableDetails) {
            const copyTableElements = tableDetails?.elements.map(el => ({
                ...el,
                objectElementName: [el?.object_name, el?.name].filter(Boolean).join('-'),
            }))
            const dubSortedElements = JSON.parse(JSON.stringify(copyTableElements)).map(ele => (
                {
                    element: ele,
                    elementId: ele?.element_id,
                    elementName: ele?.name,
                    objectId: ele?.object_id, // object_id for reference
                }));

            const dubSortingIds = dubSortedElements?.length
                ? JSON.parse(JSON.stringify(dubSortedElements.map(el => ({ element_id: el.elementId, object_id: el.objectId }))))
                : [];

            objectElementsList.forEach(el => {
                if (
                    dubSortingIds.some(id => id.element_id === el.element_id && id.object_id === el.object_id) &&
                    !dubSortedElements.some(ele => ele?.elementId === el.element_id && ele?.objectId === el.object_id)
                ) {
                    dubSortedElements.push({
                        element: el,
                        elementId: el?.element_id,
                        elementName: el?.name,
                    });
                }
            });
            setSortingElements(dubSortedElements.filter(el => (el?.element.object_id === details?.object?._id || el?.element.object_id === details?.related_object?._id)));
        }
    }, [objectElementsList, tableDetails]);


    useEffect(() => {
        if (objectElementsList.length && selectedTableDetails.settingsElements) {
            setDetails({
                ...details,
                display_name: selectedTableDetails?.settingsElements?.display_name ? selectedTableDetails?.settingsElements?.display_name : '',
                dataDuration: selectedTableDetails?.settingsElements?.data_period ? componentTableDuration.find((d) => d.id === selectedTableDetails?.settingsElements?.data_period) : null,
                description: selectedTableDetails?.settingsElements?.description ? selectedTableDetails?.settingsElements.description : '',
            });
        }
        if (objectElementsList.length && selectedTableDetails.configElements) {
            setTableRules({
                rules:
                    selectedTableDetails.configElements?.rules?.map((rule) => {
                        const matchingElement = (rule?.element_id && rule?.object_id) ?
                            objectElementsList.find((el) => el.object_id === rule.object_id && el.element_id === rule.element_id) : null;
                        return {
                            element: matchingElement,   // rule.element_id ? [...objectElementsList].find(el => el.element_id === rule?.element_id) : null,
                            elementName: matchingElement?.name || null,  // rule.element_id ? [...objectElementsList].find(el => el.element_id === rule?.element_id)?.name : null,
                            condition: rule?.condition ? tableRange.find(el => el.id === rule?.condition) : null,
                            conditionName: rule?.condition ? tableRange.find(el => el.id === rule?.condition).name : null,
                            value: rule?.value ? rule?.value : '',
                            buzz_value: rule?.value ? rule?.value : '',
                            min: rule?.min ? rule?.min : null,
                            max: rule?.max ? rule?.max : null,
                            expression: rule?.expression ? rule?.expression : null,
                        }
                    })
            });
            setResponse_elements(
                selectedTableDetails.configElements?.accumulators.map((rule) => {
                    const matchingElement = (rule?.element_id && rule?.object_id) ?
                        objectElementsList.find((el) => el.object_id === rule.object_id && el.element_id === rule.element_id) : null;

                    return {
                        element: matchingElement,  //rule?.element_id ? [...objectElementsList].find(el => el.element_id === rule?.element_id) : null,
                        elementName: matchingElement?.name || null, // rule?.element_id ? [...objectElementsList].find(el => el.element_id === rule?.element_id)?.name : null,
                        accumulator: rule?.accumulator ? accumulators.find(el => el.id === rule?.accumulator) : null,
                        accumulatorName: rule?.accumulator ? accumulators.find(el => el.id === rule?.accumulator)?.name : null,
                    }
                })
            );
            setDetails({
                ...details,
                configElements: selectedTableDetails.configElements?.elements ? selectedTableDetails.configElements?.elements : [],
                no_of_records: noofTRans.find(ele => ele.id === selectedTableDetails.configElements?.no_of_records),
                elementSortingBy: orderbyElements.find(ele => ele.id === selectedTableDetails.configElements?.sort?.order),
                elementSortingOn: objectElementsList.find(ele =>
                    ele.object_id === selectedTableDetails.configElements?.sort?.object_id &&
                    ele.element_id === selectedTableDetails.configElements?.sort?.element_id
                ),
                groupTransTable: objectElementsList.find(ele =>
                    ele.object_id === selectedTableDetails.configElements.group_by[0]?.object_id &&
                    ele.element_id === selectedTableDetails.configElements.group_by[0]?.element_id
                ),
                // elementSortingOn: objectElementsList.find(ele => ele.element_id === selectedTableDetails.configElements?.sort?.element_id),
                // groupTransTable: selectedTableDetails.configElements?.group_by ? objectElementsList.find(ele => ele.element_id === selectedTableDetails.configElements.group_by[0]?.element_id) : null
            });
        }
    }, [objectElementsList, selectedTableDetails.settingsElements, selectedTableDetails.configElements]);

    // ----------------industries------------
    const getIndustries = () => {
        Axios.get(GET_INDUSTRIES_LIST(), { params: { page: 0, limit: 1000 } })
            .then(res => {
                if (res.data) {
                    let data = res.data.map((el) => {
                        return {
                            name: el.name,
                            id: el._id,
                            ...el
                        }
                    })
                    setIndustries(data);
                }
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Industries', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }

    // ------------get table details------------
    // -----------table info step-----------
    const getTableInfo = () => {
        Axios.get(SELECTED_DATASET(props.match?.params?.tableId))
            .then(res => {
                const response = res.data;
                setSelectedTable(response);
                setTableDetails(response);
                const templateDetails = {
                    ...details,
                    // industry: response?.industry_id ? industries?.find((d) => d._id === response?.industry_id) : null,
                    // component: response?.component ? componentsList.find((d) => d.id === response?.component) : null,
                    // name: response?.name ? response?.name : '',
                    // object: response?.object ? objects?.find((d) => d._id === response?.object_id) : null,
                    // related_object: response?.related_object ? objects?.find((d) => d._id === response?.related_object_id) : null
                };
                // setDetails({ ...templateDetails });
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.message : 'Failed to fetch Details.', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }
    // -----------table settings 2nd step ------------
    const getTableSettingsStep = () => {
        Axios.get(SELECTED_DATASET_TABLE_CONFIG(props.match?.params?.tableId))
            .then(res => {
                const response = res.data;
                setSelectedTableDetails({ ...selectedTableDetails, settingsElements: response })
                // setDetails({
                //     ...details,
                // name: response?.name ? response?.name : '',
                // display_name: response?.display_name ? response?.display_name : '',
                // // object: response?.object ? objects?.find((d) => d._id === response?.object_id) : null,
                // // related_object: response?.related_object ? objects?.find((d) => d._id === response?.related_object_id) : null,
                // dataDuration: response?.data_period ? componentTableDuration.find((d) => d.id === response?.data_period) : null,
                // description: response?.description ? response.description : '',
                // });


            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.message : 'Failed to tableeeeeeeeeeeee fetch Config Details.', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }
    // -------table elements rule 3rd step-----------
    const getTableConfigStep = () => {
        Axios.get(SELECTED_DATASET_DISPLAY_CONFIG(props.match?.params?.tableId))
            .then(res => {
                const response = res.data;
                setSelectedTableDetails({ ...selectedTableDetails, configElements: response })
                // setDetails({
                //     ...details,
                //     // elementSortingOn: objectElementsList.find(ele => ele.element_id === response?.sort?.element_id),
                //     configElements: response?.elements ? response?.elements : [],
                //     no_of_records: noofTRans.find(ele => ele.id === response?.no_of_records),
                //     elementSortingBy: orderbyElements.find(ele => ele.id === response?.sort?.order),
                //     // groupTransTable: response.group_by ? objectElementsList.filter(obj => response.group_by.map(grp => grp?.element_id).includes(obj.element_id)) : null
                //     // groupTransTable: response.group_by ? objectElementsList.find(ele => ele.element_id === response.group_by[0]?.element_id) : null
                // });
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.message : 'Failed to fetch  Config Details.', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }


    // -----get getSystem Objects lists -----
    const getObjects = () => {
        setLoading(true);
        Axios.get(GET_OBJECTS, { params: { industry_id: details.industry?._id, active: true, page: 0, limit: 1000 } })
            .then(response => {
                setObjects(response.data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.message : 'Failed to fetch Objects.', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }

    // ---------object elements-----
    const getObjectElements = (objId) => {
        Axios.get(GET_OBJECT_ELEMENTS(objId), { params: { page: 0, limit: 1000 } })
            .then(res => {
                if (res.data) {
                    let data = res.data.map(el => {
                        return {
                            ...el,
                            id: el.element_id,
                            view: false,
                            name: el?.name,
                            key: el?.key,
                            display: [el?.object_name, el?.name].filter(Boolean).join('-'),
                            attribute: `${el.object_id}_${el.key}`,
                            objectElementName: [el?.object_name, el?.name].filter(Boolean).join('-'),
                        }
                    })
                    setObjElementsList(data);
                    // setObjectElementsList((prevList) => {
                    //     const updatedList = [...prevList, ...data];
                    //     const uniqueList = Array.from(new Map(updatedList.map(item => [item.id, item])).values());
                    //     return uniqueList;
                    // });
                    setLoading(false);
                }
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Elements', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }
    const getRelatdObjectElements = (objId) => {
        Axios.get(GET_OBJECT_ELEMENTS(objId), { params: { page: 0, limit: 1000 } })
            .then(res => {
                if (res.data) {
                    let data = res.data.map(el => {
                        return {
                            ...el,
                            id: el.element_id,
                            view: false,
                            name: el?.name,
                            key: el?.key,
                            display: [el?.object_name, el?.name].filter(Boolean).join('-'),
                            attribute: `${el.object_id}_${el.key}`,
                            objectElementName: [el?.object_name, el?.name].filter(Boolean).join('-'),
                        }
                    })
                    setRelObjectElementsList(data);
                    // setObjectElementsList((prevList) => {
                    //     const updatedList = [...prevList, ...data];
                    //     const uniqueList = Array.from(new Map(updatedList.map(item => [item.id, item])).values());
                    //     return uniqueList;
                    // });
                    setLoading(false);
                }
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Elements', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }



    // -----steps changes---------
    const handleNext = (e, data) => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
        setQuery({ step: transactionSetupSteps[activeStep - 1] })
        query.step = transactionSetupSteps[activeStep - 1];
        const searchString = queryString.stringify(query);
        props.history.push({
            pathname: `/platform-admin/transactiondata/${props.match?.params?.tableId ? props.match?.params?.tableId : 'new'}`,
            search: searchString
        });
    };
    const handleSetQuery = seasonObj => {
        setQuery(seasonObj);
        // setActiveStep(prevActiveStep => prevActiveStep + 1);
    };
    const handleStep = step => () => {
        query.step = transactionSetupSteps[step];
        const searchString = queryString.stringify(query);
        setActiveStep(step);
        props.history.push({
            pathname: `/platform-admin/transactiondata/${props.match?.params?.tableId}`,
            search: searchString
        });
    };


    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return (
                    <TableInfo
                        activeStep={activeStep}
                        previous={handleBack}
                        next={handleNext}
                        details={details}
                        objects={objects}
                        industries={industries}
                        status={currentSeasonStatus}
                        objectElementsList={objectElementsList}
                        {...props}
                        {...query}
                        handleSetQuery={handleSetQuery}
                        handleChange={handleChange}
                        handleChangeAutocomplete={handleChangeAutocomplete}
                    />
                );
            case 1:
                return (
                    <TableSettings
                        activeStep={activeStep}
                        previous={handleBack}
                        next={handleNext}
                        details={details}
                        objects={objects}
                        setDetails={setDetails}
                        status={currentSeasonStatus}
                        objectElementsList={objectElementsList}
                        response_elements={response_elements}
                        sortingElements={sortingElements}
                        tableRules={tableRules}
                        {...props}
                        {...query}
                        handleSetQuery={handleSetQuery}
                        handleChange={handleChange}
                        handleChangeAutocomplete={handleChangeAutocomplete}
                        handleSummarizedUpdate={handleSummarizedUpdate}
                        handleElementSortingCreate={handleElementSortingCreate}
                        handleElementSortingUpdate={handleElementSortingUpdate}
                        handleElementSortingDelete={handleElementSortingDelete}
                        onDragEnd={onDragEnd}
                    />
                );
            case 2:
                return (
                    <TableElementConfig
                        activeStep={activeStep}
                        previous={handleBack}
                        next={handleNext}
                        details={details}
                        sortingElements={sortingElements}
                        status={currentSeasonStatus}
                        editElement={editElement}
                        objectElementsList={objectElementsList}
                        response_elements={response_elements}
                        tableRules={tableRules}
                        {...props}
                        {...query}
                        handleSetQuery={handleSetQuery}
                        handleChange={handleChange}
                        handleChangeAutocomplete={handleChangeAutocomplete}
                        handleDrop={handleDrop}
                        onElementEditClick={onElementEditClick}
                        onElementEyeClick={onElementEyeClick}
                        handleElementSubmit={handleElementSubmit}
                        handleElementClose={handleElementClose}
                        elementDisplayChange={elementDisplayChange}
                        displayValue={displayValue}
                        handleSummarizedUpdate={handleSummarizedUpdate}
                        handleSummarizedCreate={handleSummarizedCreate}
                        handleSummarizedDelete={handleSummarizedDelete}
                        handleCreate={handleCreate}
                        handleUpdate={handleUpdate}
                        handleDelete={handleDelete}
                        handleOpenExpressionDialog={handleOpenExpressionDialog}
                    />
                );
            default:
                return 'Unknown stepIndex';
        }
    }

    // ---------------input fields and auto complete ------------
    const handleChange = (event, field) => {
        setDetails({ ...details, [field]: event.target.value });
    };
    const handleChangeAutocomplete = (data, field) => {
        if (field === 'object') {
            setDetails({ ...details, [field]: data, related_object: null, configElements: [] });
            // setObjectElementsList([]);
            setObjElementsList([]);
            setRelObjectElementsList([]);
            setSortingElements([]);
        }
        else if (field === 'related_object') {
            setDetails({ ...details, [field]: data, configElements: [] });
            // setObjectElementsList([]);
            setRelObjectElementsList([]);
            setSortingElements([]);
        }
        else if (field === 'configElements') {
            if (data.find(option => option.all)) {
                setDetails({ ...details, [field]: details.configElements.length === objectElementsList.length ? [] : objectElementsList });
                setSortingElements(objectElementsList);
            }
            else {
                setDetails({ ...details, [field]: data });
                setSortingElements(data)
            }
        }
        // else if (field === 'groupTransTable') {
        //     if (data.find(option => option.all)) setDetails({ ...details, [field]: details.groupTransTable.length === objectElementsList.length ? [] : objectElementsList });
        //     else setDetails({ ...details, [field]: data });

        // }
        else {
            setDetails(prevState => {
                return { ...prevState, [field]: data }
            });
        }
    };


    // display step Elements Drag to update list on drop
    const handleDrop = (droppedItem) => {
        if (!droppedItem.destination) return;
        var updatedList = [...sortingElements];
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        setSortingElements(updatedList);
    };
    const onElementEditClick = (item) => {
        setEditElement(item);
        setDispalyValue(item.name);
    }
    const onElementEyeClick = (item) => {
        let elementListDup = JSON.parse(JSON.stringify(sortingElements));
        elementListDup = elementListDup.map(e => {
            if (e.id === item.id) e['view'] = !e['view'];
            return e;
        });
        setSortingElements([...elementListDup]);
    };
    const elementDisplayChange = (e) => {
        const dName = e.target.value;
        let displayDup = JSON.parse(JSON.stringify(sortingElements));
        displayDup = displayDup.map(elem => {
            if (elem.id === editElement.id) elem['name'] = dName;
            return elem;
        });
        setSortingElements([...displayDup]);
        setDispalyValue(dName);
    }
    const handleElementSubmit = () => { }
    const handleElementClose = () => {
        setEditElement(null);
    }

    // --------------summarized table changes----------------
    const handleSummarizedCreate = (rowData, resolve, reject) => {
        let newData = response_elements
        if (!rowData?.element) {
            enqueueSnackbar('Please enter Element.', {
                variant: 'error',
                preventDuplicate: true
            });
            reject();
        }
        else {
            let finalRowData = {
                ...rowData,
                element: rowData?.element || null,
                elementId: rowData?.element?.elementId || '',
                elementName: rowData?.element?.elementName || null,
                accumulatorName: rowData?.accumulator ? rowData.accumulator.name : null,
            };
            // let finalRowData = rowData;
            // finalRowData.elementName = finalRowData?.element ? finalRowData.element.name : null;
            // finalRowData.accumulatorName = finalRowData?.accumulator ? finalRowData.accumulator.name : null;
            newData.unshift(finalRowData);
            setResponse_elements(newData.map((ele, ind) => ({ ...ele, tableData: { id: ind } })));
            resolve();
        }
    }

    const handleSummarizedUpdate = (newData, oldData, resolve, reject) => {
        const index = oldData.tableData.id;
        const updateRules = response_elements[index];
        updateRules.element = newData?.element ? (newData?.element.element || newData?.element) : null;
        updateRules.elementId = newData?.element ? (newData?.element.element_id || newData?.elementId) : null;
        updateRules.elementName = newData?.element ? (newData?.element.elementName || newData?.elementName) : null;
        updateRules.accumulator = newData?.accumulator ? newData?.accumulator : null;
        updateRules.accumulatorName = newData?.accumulator ? newData?.accumulator?.name : null;
        resolve();
        setResponse_elements([...response_elements, ...updateRules]);
    }
    const handleSummarizedDelete = (oldData, resolve, reject) => {
        let updateRules = JSON.parse(JSON.stringify(response_elements));
        updateRules.splice(oldData.tableData.id, 1);
        setResponse_elements([...updateRules]);
        resolve();
    }
    // -----------------element sorting drag and drop------------
    const handleElementSortingCreate = (rowData) => {
        let newData = sortingElements;
        if (Array.isArray(rowData.element)) {
            rowData.element.forEach((element) => {
                const finalRowData = {
                    // ...rowData,
                    element: element,
                    elementName: element.name || element.display_name,
                    elementId: element.element_id,
                };
                newData.push(finalRowData);
            });
        } else {
            const finalRowData = {
                // ...rowData,
                element: rowData.element,
                elementName: rowData.element ? (rowData.element.name || rowData.element.display_name) : null,
                elementId: rowData.element ? rowData.element.element_id : null,
            };
            newData.push(finalRowData);
        }
        setSortingElements([...newData]);
    };

    const handleElementSortingUpdate = (newData) => {
        const index = newData.index;
        const updateRules = sortingElements[index];
        updateRules['element'] = newData ? newData : null;
        updateRules['elementName'] = newData ? (newData?.name || newData?.display_name) : null;
        updateRules['elementId'] = newData ? (newData?.element_id) : null;
    }
    const handleElementSortingDelete = (oldData) => {
        let updateData = JSON.parse(JSON.stringify(sortingElements));
        updateData.splice(oldData.tableData.id, 1);
        setSortingElements([...updateData])
    }
    // Reorder rows function
    const onDragEnd = (result) => {
        const { destination, source } = result;
        if (!destination || destination.index === source.index) return;
        const reorderedData = Array.from(sortingElements);
        const [removed] = reorderedData.splice(source.index, 1);
        reorderedData.splice(destination.index, 0, removed);
        setSortingElements(reorderedData);
    };
    // --------------summarized table changes----------------


    // -------------filter settings changes-------------
    const handleCreate = (rowData, resolve, reject) => {
        let newData = tableRules.rules
        if (!rowData?.element) {
            enqueueSnackbar('Please enter Element.', {
                variant: 'error',
                preventDuplicate: true
            });
            reject();
        }
        else {
            let finalRowData = rowData;
            finalRowData['value'] = finalRowData?.value ? finalRowData.value : regularExpRemoveChar(expressionMessage.body, '@_');
            finalRowData['buzz_value'] = finalRowData?.value ? finalRowData.value : regularExpRemoveChar(expressionMessage.body, '@_');
            finalRowData['expression'] = {
                body: expressionMessage?.body && expressionMessage.body,
                expression: expressionMessage?.body && regularExpRemoveChar(expressionMessage.body, '@_'),
            }
            finalRowData['elementName'] = finalRowData?.element ? (finalRowData?.element?.name || finalRowData?.element?.display_name) : null;
            finalRowData['conditionName'] = finalRowData?.condition ? finalRowData.condition.name : null;
            newData.unshift(finalRowData);
            setTableRules(prevState => { return { ...prevState, rules: newData.map((ele, ind) => ({ ...ele, tableData: { id: ind } })) } })
            resolve();
            setExpressionMessage({
                body: '',
                expression: '',
                basedOn: null,
                search: ''
            });
        }
    }
    const handleUpdate = (newData, oldData, resolve, reject) => {
        if (!newData?.element) {
            enqueueSnackbar('Please enter Element.', {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else {
            const index = oldData.tableData.id;
            const updateRules = tableRules.rules[index];
            updateRules['value'] = newData?.value ? newData?.value : (expressionMessage?.body && regularExpRemoveChar(expressionMessage.body, '@_'));
            updateRules['buzz_value'] = newData?.value ? newData?.value : (expressionMessage?.body && regularExpRemoveChar(expressionMessage.body, '@_'));
            updateRules['expression'] = (newData?.value === newData?.expression?.body) ? newData?.expression : {
                body: expressionMessage?.body && expressionMessage.body,
                expression: expressionMessage?.body && regularExpRemoveChar(expressionMessage.body, '@_'),
            }
            updateRules['element'] = newData?.element ? newData?.element : null;
            updateRules['condition'] = newData?.condition ? newData?.condition : null;
            updateRules['conditionName'] = newData?.condition ? newData.condition.name : null;
            updateRules['elementName'] = newData?.element ? (newData?.element?.name || newData?.element?.display_name) : null;
            setTableRules(prevState => { return { ...prevState, ...updateRules } })
            resolve();
            setExpressionMessage({
                body: '',
                expression: '',
                basedOn: null,
                search: ''
            });
        }
    }
    const handleDelete = (oldData, resolve, reject) => {
        let updateRules = JSON.parse(JSON.stringify(tableRules.rules));
        updateRules.splice(oldData.tableData.id, 1);
        setTableRules(prevState => { return { ...prevState, rules: updateRules ? updateRules : [] } })
        resolve();
    }
    // -------------filter settings changes-------------


    //expression dialog actions
    const handleOpenExpressionDialog = (props, value) => {
        setExpressionBuilderDialog(true);
        if (value) {
            setExpressionMessage(prevState => ({ ...prevState, body: value, rowOnChange: props.onChange }));
        }
        else {
            setExpressionMessage({
                body: '',
                expression: '',
                basedOn: null,
                search: '',
                rowOnChange: props.onChange
            });
        }
    }
    const handleCloseExpressionDialog = (value) => {
        setExpressionBuilderDialog(false);
        setExpressionMessage({
            body: '',
            expression: '',
            basedOn: null,
            search: '',
            rowOnChange: null
        });
    };
    const handleChangeExpressionMessage = (event, field) => {
        setExpressionMessage({ ...expressionMessage, [field]: event.target.value });
    }
    const handleAddExpressionUsers = (id, display) => {
        const expressionTagsCopy = JSON.parse(JSON.stringify(expressiontags));
        if (!expressionTagsCopy.map(ele => ele.id).includes(id)) setExpressionTags([...expressiontags, { key: id, name: display }]);
    }
    const expressionClick = (exp) => {
        setExpressionMessage({ ...expressionMessage, body: expressionMessage?.body + exp?.value });
    }
    const handleExpressionSubmit = () => {
        const { rowOnChange } = { ...expressionMessage };
        rowOnChange(expressionMessage.body.replaceAll('@_', ''))
        setExpressionBuilderDialog(false);
    }
    const handleExpressionClearData = () => {
        setExpressionBuilderDialog(false);
        setExpressionMessage({
            body: '',
            expression: '',
            basedOn: null,
            search: '',
            rowOnChange: null
        });

    }
    const handleAppendExpElement = (data) => {
        const tagsCopy = JSON.parse(JSON.stringify(expressiontags));
        setExpressionMessage({ ...expressionMessage, body: expressionMessage.body + `@@_${data?.objectElementName}@_` })
        if (!tagsCopy.map(ele => ele.id).includes(data.key)) setExpressionTags([...expressiontags, { ...data }]);
    }
    //expression dialog actions

    // ---------------submit steps------------
    const handleSubmit = () => {
        if (!industryConfirmDialog && activeStep === 0 && props.match?.params?.tableId && (details.industry._id !== tableDetails?.industry_id)) {
            setIndustryConfirmDialog(true);
        }
        else {
            if (activeStep === 0 && (!details.industry || !details.component)) {
                enqueueSnackbar(`Please enter required Table info fields`, {
                    variant: 'error',
                    preventDuplicate: true
                });
                setLoading(false);
            }
            else if (activeStep === 1 && (!details?.name.trim() === "" || !details.dataDuration || !details.object)) {
                enqueueSnackbar(`Please enter required fields`, {
                    variant: 'error',
                    preventDuplicate: true
                });
                setLoading(false);
            }
            else if (activeStep === 2 && (!details.elementSortingOn || !details.elementSortingBy || !details.no_of_records || !details.groupTransTable)) {
                enqueueSnackbar(`Please enter required configuration fields`, {
                    variant: 'error',
                    preventDuplicate: true
                });
                setLoading(false);
            }
            else {
                let requestObj = {}
                if (activeStep === 0) {
                    requestObj = {
                        industry_id: details.industry?.id,
                        component: details.component?.id,
                    }
                }
                else if (activeStep === 1) {
                    requestObj = {
                        name: details.name,
                        display_name: details.display_name,
                        data_period: details.dataDuration?.id,
                        description: details.description,
                        object_id: details.object?._id,
                        related_object_id: details.related_object?._id,
                        elements: sortingElements?.map(obj => ({
                            name: obj?.elementName ? obj.elementName : null,
                            label: obj?.element?.label ? obj?.element?.label : obj?.element.name,
                            element_id: obj?.element ? obj.element?.element_id : null,
                            key: obj?.element ? obj.element.key : null,
                            object_id: obj.element?.object_id || null
                        })) || []
                    };
                }
                else if (activeStep === 2) {
                    const replaceAttributeKeys = (text, objectElementsList) => {
                        if (text && objectElementsList) {
                            objectElementsList.forEach(attr => {
                                const regex = new RegExp(attr.objectElementName.replace(rulesRegularExpression, '\\$&'), 'g');
                                text = text.replace(regex, (`${attr.key}_${attr.object_id}`));
                            });
                            return text.replace(new RegExp('@', 'g'), '');
                        }
                    }
                    requestObj = {
                        group_by: details.groupTransTable?.element?.element_id || details.groupTransTable.element_id || details.groupTransTable.elementId, //map(el => el?.element_id),
                        accumulators: response_elements.length ? response_elements.map(el => ({
                            element_id: el?.element?.element?.element_id || el?.element?.element_id,
                            accumulator: el?.accumulator?.id ? el?.accumulator?.id : '',
                            object_id: el?.element?.element?.object_id || el?.element?.object_id || null
                        })) : [],
                        rules: tableRules.rules?.map(obj => ({
                            element_id: obj?.element ? obj?.element?.element_id : '',
                            object_id: obj?.element ? obj?.element?.object_id : null,
                            attribute: obj.element ? `${obj.element?.key}_${obj.element?.object_id}` : null,
                            key: obj?.element ? obj?.element?.key : '',
                            element: obj?.element ? obj?.element?.name : '',
                            condition: obj?.condition ? obj?.condition?.id : '',
                            value: obj?.value ? obj?.value : '',
                            min: obj?.min ? obj?.min : '',
                            max: obj?.max ? obj?.max : '',
                            type: obj?.expression?.body.length > 0 ? 'EXPRESSION' : 'CONSTANT',
                            expression: {
                                body: obj?.expression?.body.replaceAll('@_', ''),
                                expression: replaceAttributeKeys(obj.expression?.expression, objectElementsList)
                            }
                        })) || [],
                        no_of_records: details.no_of_records?.id,
                        sort: {
                            element_id: details?.elementSortingOn?.element_id || details?.elementSortingOn?.element?.element_id,
                            object_id: details?.elementSortingOn?.object_id || details?.elementSortingOn?.element?.object_id || null,
                            enabled: false,
                            key: details?.elementSortingOn?.key || details?.elementSortingOn?.element?.key,
                            order: details?.elementSortingBy?.id ? details?.elementSortingBy?.id : null
                        }
                    };
                }
                setLoading(true);
                if (activeStep === 0) {
                    const ReqMethod = (props.match?.params?.tableId || tableDetails?._id) ? Axios.put(SELECTED_DATASET(props.match?.params?.tableId || tableDetails?._id), requestObj) : Axios.post(DATASETS, requestObj)
                    ReqMethod.then(response => {
                        setTableDetails(response.data);
                        let elem_data = response.data?.elements.map(el => {
                            return {
                                ...el,
                                name: el?.name,
                                id: el._id,
                                accumulator: null,
                                key: el?.key,
                                display: el?.name,
                                // id: el?.key,
                            }
                        })
                        // setResponse_elements(elem_data);
                        setLoading(false);
                        if (activeStep !== transactionSetupSteps.length - 1) {
                            setActiveStep(activeStep + 1)
                            setQuery({ step: transactionSetupSteps[1] })
                            query.step = transactionSetupSteps[activeStep + 1];
                            const searchString = queryString.stringify(query);
                            props.history.push({
                                pathname: `/platform-admin/transactiondata/${props.match?.params?.tableId ? props.match?.params?.tableId : 'new'}`,
                                search: searchString
                            });
                        }
                    })
                        .catch(error => {
                            setLoading(false);
                            enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed Dataset.', {
                                variant: 'error',
                                preventDuplicate: true
                            });
                        });
                }
                else if (activeStep === 1) {
                    Axios.put(SELECTED_DATASET_TABLE_CONFIG(tableDetails?._id), requestObj)
                        .then(response => {
                            if (activeStep !== transactionSetupSteps.length - 1) {
                                setActiveStep(activeStep + 1)
                                setQuery({ step: transactionSetupSteps[2] })
                                query.step = transactionSetupSteps[activeStep + 1];
                                const searchString = queryString.stringify(query);
                                props.history.push({
                                    pathname: `/platform-admin/transactiondata/${props.match?.params?.tableId ? props.match?.params?.tableId : 'new'}`,
                                    search: searchString
                                });
                            }
                            setLoading(false);
                        })
                        .catch(error => {
                            setLoading(false);
                            enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to add Rules.', {
                                variant: 'error',
                                preventDuplicate: true
                            });
                        });
                }
                else if (activeStep === 2) {
                    Axios.put(SELECTED_DATASET_DISPLAY_CONFIG(tableDetails?._id), requestObj)
                        .then(response => {
                            enqueueSnackbar(props.match?.params?.tableId ? "Transaction Data Updated Successfully." : "Transaction Data Created Successfully.", {
                                variant: "Success",
                                preventDuplicate: true,
                            });
                            props.history.push({
                                pathname: `/platform-admin/transactiondata`,
                            });
                            setLoading(false);
                        })
                        .catch(error => {
                            setLoading(false);
                            enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to add Display Configuration.', {
                                variant: 'error',
                                preventDuplicate: true
                            });
                        });
                }
            }
        }
    }

    // -------industry change alerts------
    const handleConfirmationDialog = (value) => {
        if (value) {
            handleSubmit();
            setIndustryConfirmDialog(false);
        }
        else setIndustryConfirmDialog(false);
    };


    const breadcrumbsList = [
        { label: 'Platform Admin', to: '/platform-admin' },
        { label: 'Transaction Data', to: `/platform-admin/transactiondata` },
        { label: props.match?.params?.tableId ? tableDetails?.name : 'New' }
    ]

    return (
        <>
            <div className={classes.wrapper}>
                <CustomBreadcrumbs title={query.title} crumbsList={breadcrumbsList} />
                <div className={classes.root}>
                    <div>
                        <div>
                            <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
                                {transactionSetupSteps.map((label, index) => (
                                    <Step key={label}>
                                        <StepButton onClick={handleStep(index)} disabled={selectedTable ? false : true} completed={activeStep ? (index > activeStep) ? false : (activeStep !== index) : false} > {/* && index !== 1 */}
                                            {label}
                                        </StepButton>
                                    </Step>
                                ))}
                            </Stepper>
                        </div>
                        <div className={classes.stepperContentRoot}>
                            {getStepContent(activeStep)}
                        </div>
                    </div>
                    <div className={classes.stepperActions}>
                        {activeStep === 0 && <Button onClick={() => props.history.push('/platform-admin/transactiondata')} className={classes.backButton}>Cancel</Button>}
                        {activeStep !== 0 && < Button onClick={handleBack} className={classes.backButton}>Back</Button>}
                        <Button variant="contained" color="primary" onClick={handleSubmit}> {activeStep === transactionSetupSteps.length - 1 ? 'Save' : 'Next'}</Button>
                    </div>
                </div>
            </div>
            {
                expressionBuilderDialog && (
                    <ExpressionDialog
                        open={expressionBuilderDialog}
                        handleClose={handleCloseExpressionDialog}
                        expressionMessage={expressionMessage}
                        setExpressionMessage={setExpressionMessage}
                        handleChangeExpressionMessage={handleChangeExpressionMessage}
                        expressionElements={objectElementsList}
                        handleAddExpressionUsers={handleAddExpressionUsers}
                        expressionClick={expressionClick}
                        handleExpressionSubmit={handleExpressionSubmit}
                        handleExpressionClearData={handleExpressionClearData}
                        handleAppendExpElement={handleAppendExpElement}
                    />
                )
            }
            {industryConfirmDialog && (
                <CustomDialog
                    primaryButton={{ text: "YES" }}
                    secondaryButton={{ text: "NO" }}
                    type="CONFIRMATION"
                    open={industryConfirmDialog}
                    content={<span>
                        Warning: Changing the industry will erase all existing configurations, including custom objects, elements, applied rules, and other settings.
                        This action cannot be undone. Do you wish to proceed?
                    </span>}
                    handleConfirmationDialog={handleConfirmationDialog}
                    maxWidth={"sm"}
                />
            )}
        </>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value))
    };
};

export default withRouter(connect(null, mapDispatchToProps)(TransactionStepper));
